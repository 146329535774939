import axios, { type AxiosInstance } from 'axios'
import * as _ from 'lodash-es'
import { type Pinia, storeToRefs } from 'pinia'

import { useUserStore } from '@src/stores/user.store'

import { useOauth } from '@src/hooks/useOauth.hook'
import { usePopup } from '@src/hooks/usePopup.hook'

import { type Oauth } from '@src/modules/oauth/oauth.module'

import { getMessageMarkup, keysToCamelCase, keysToSnakeCase } from '@src/utils/api.util'

let api: AxiosInstance

export function createApi(oauth: Oauth, pinia?: Pinia) {
  api = axios.create({ baseURL: import.meta.env.VITE_API_BASE_URL })

  const { grantorId } = storeToRefs(useUserStore(pinia))

  const controllerMap: Record<string, AbortController> = {}

  api.interceptors.request.use((config) => {
    if (config.cancelKey) {
      controllerMap[config.cancelKey]?.abort()
      controllerMap[config.cancelKey] = new AbortController()
    }

    return { ...config, signal: config.cancelKey ? controllerMap[config.cancelKey]?.signal : undefined }
  })

  api.interceptors.response.use((response) => {
    if (response.config.cancelKey) {
      delete controllerMap[response.config.cancelKey]
    }

    return response
  })

  api.interceptors.request.use((config) => {
    return {
      ...config,
      data: keysToSnakeCase(config.data),
      params: keysToSnakeCase(config.params),
    }
  })

  api.interceptors.request.use((config) => {
    if (config.withGrantor) {
      config.params = _.merge({}, config.params, { filter: { grantorId: grantorId.value } })
    }

    return config
  })

  api.interceptors.response.use(
    (response) => keysToCamelCase(response.data),
    (error: unknown) => {
      if (!axios.isAxiosError(error)) {
        return Promise.reject(error)
      }

      const showNotification = usePopup(() => import('@src/components/base/notification/VNotification.vue'))

      const message = getMessageMarkup(error)

      if (message) {
        if (error.response?.status === 500) {
          showNotification({
            message:
              'Что-то пошло не так, пожалуйста, обратитесь в <a href="https://vk.me/cerebro_vk" target="_blank">техподдержку Церебро</a>',
            duration: 7000,
            color: 'danger',
          })
        } else if (error.response?.status === 403) {
          showNotification({
            message:
              'Недостаточно прав доступа, пожалуйста, обратитесь в <a href="https://vk.me/cerebro_vk" target="_blank">техподдержку Церебро</a>',
            duration: 7000,
            color: 'danger',
          })
        } else {
          showNotification({ message, duration: 7000, color: 'danger' })
        }
      }

      return Promise.reject(error)
    }
  )

  oauth.useInterceptors(api)

  return api
}

export function useApi() {
  if (!api) {
    const oauth = useOauth()

    createApi(oauth)
  }

  return api
}
