import { type DeepReadonly } from 'vue'
import { type RouteRecordRaw } from 'vue-router'

import { RouteName } from '@src/types/router.type'

export const clientsRoute = {
  name: RouteName.Clients,
  path: '/clients',
  component: () => import('@src/views/clients/Clients.vue'),
  redirect: { name: RouteName.ClientList },
  children: [
    {
      name: RouteName.ClientList,
      path: '',
      component: () => import('@src/views/clients/views/list/ClientList.vue'),
    },
    {
      name: RouteName.ClientView,
      path: ':clientId(\\d+)',
      component: () => import('@src/views/clients/views/view/ClientView.vue'),
    },
    {
      name: RouteName.ClientBonusesAllocate,
      path: ':clientId(\\d+)/bonuses-allocate',
      component: () => import('@src/views/clients/views/bonusesAllocate/ClientBonusesAllocate.vue'),
      meta: { canView: ({ permissions }) => permissions.has('app.bonuses.allocate') },
    },
    {
      name: RouteName.ClientCreate,
      path: 'create',
      component: () => import('@src/views/clients/views/save/ClientSave.vue'),
    },
    {
      name: RouteName.ClientUpdate,
      path: ':clientId(\\d+)/update',
      component: () => import('@src/views/clients/views/save/ClientSave.vue'),
    },
  ],
} satisfies DeepReadonly<RouteRecordRaw>
