export enum RouteName {
  Root = 'root',
  NotFound = 'not-found',

  OauthCallback = 'oauth-callback',
  Impersonate = 'impersonate',

  AdvertiserActs = 'advertiser-acts',
  AdvertiserActList = 'advertiser-act-list',
  AdvertiserActCard = 'advertiser-act-card',
  AdvertiserActCreate = 'advertiser-act-create',
  AdvertiserActUpdate = 'advertiser-act-update',

  Advertisers = 'advertisers',
  AdvertiserView = 'advertiser-view',
  AdvertiserCreate = 'advertiser-create',
  AdvertiserUpdate = 'advertiser-update',

  Cabinets = 'cabinets',
  CabinetView = 'cabinet-view',
  CabinetCreate = 'cabinet-create',
  CabinetRefill = 'cabinet-refill',
  CabinetRefund = 'cabinet-refund',

  Bonuses = 'bonuses',

  Clients = 'clients',
  ClientList = 'client-list',
  ClientView = 'client-view',
  ClientBonusesAllocate = 'client-bonuses-allocate',
  ClientCreate = 'client-create',
  ClientUpdate = 'client-update',

  CreationTool = 'creation-tool',

  Documents = 'documents',
  DocumentList = 'documents-list',

  Invoices = 'invoices',
  InvoiceList = 'invoice-list',
  InvoiceCard = 'invoice-card',
  InvoiceCreate = 'invoice-create',

  MonthlyActs = 'monthly-acts',
  MonthlyActList = 'monthly-act-list',

  Points = 'points',
  PointsShop = 'points-shop',
  PointsHistory = 'points-history',
  PointsBuy = 'points-buy',

  Profile = 'profile',
  ProfileEdit = 'profile-edit',
  ProfileExchangePassword = 'profile-exchange-password',
  ProfileNotifications = 'profile-notifications',

  Tasks = 'tasks',
  TaskList = 'task-list',

  Transactions = 'transactions',
  TransactionList = 'transaction-list',
}
