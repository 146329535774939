/// <reference types="vite/client" />

import { acceptHMRUpdate, defineStore, storeToRefs } from 'pinia'

import { useUserStore } from '@src/stores/user.store'

import { useOauth } from '@src/hooks/useOauth.hook'

export const useAuthStore = defineStore('auth', () => {
  const oauth = useOauth()

  const userStore = useUserStore()

  const { user } = storeToRefs(userStore)

  async function login() {
    try {
      await Promise.all([userStore.fetchUser(), userStore.fetchPermissions()])
    } catch (e) {}
  }

  async function logout() {
    try {
      if (oauth.isAuthorized) {
        const { accessToken } = await oauth.getTokens()

        await oauth.reset()

        const form = document.createElement('form')
        const input = document.createElement('input')

        form.action = `${import.meta.env.VITE_AUTH_BASE_URL}/logout`
        form.method = 'POST'
        input.type = 'hidden'
        input.name = 'token'
        input.value = accessToken ?? ''
        form.appendChild(input)
        document.body.appendChild(form)
        form.submit()
      }
    } catch (e) {}
  }

  return {
    user,
    logout,
    login,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}
